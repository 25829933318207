import Session from '@/handler/session'

var errorToken = {
  valid (error) {        
    if (error.response != undefined) {
      if(error.response.data != undefined) {
        if(error.response.data.token_error != undefined) {      
          Session.removeSessionAuthTokenProblem()
          return error.response.data.message
        }          
      }
    }
  }
}

export default errorToken
