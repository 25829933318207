<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Depósitos</h4>
            <div class="small text-muted">Administración de todos los depósitos</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col md="9">
          <b-row>
            <b-col md="4">
              <Widget :configWidget="configWidget" reference="cant_deposits" :hasAccess="configWidget.elements.cantDeposits" />
            </b-col>
            <b-col md="4">
              <Widget :configWidget="configWidget" reference="list_deposit_zone_empty" :hasAccess="configWidget.elements.listDepositZoneEmpty" />
            </b-col>
          </b-row>
        </b-col>  
        <b-col md="3">
          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openDeposits()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Depósitos</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Configuración de los depósitos y sus zonas
              </p>
            </b-list-group-item>
          </b-list-group>

          <b-list-group class="mt-2">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openDepositsOperations()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Operaciones</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Operaciones en depósito de productos
              </p>
            </b-list-group-item>
          </b-list-group>          

          <b-list-group class="mt-2">
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openDepositsQueryLocations()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Stock por Ubicación</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Consulta del movimiento de stock por ubicación
              </p>
            </b-list-group-item>
          </b-list-group>  

          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openDepositsQuery()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Stock por Productos</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Consulta del movimiento de stock por producto
              </p>
            </b-list-group-item>
          </b-list-group>                     
        </b-col>        
      </b-row>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.DEPOSITOS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        configWidget: {
          module_id: Modules.DEPOSITOS,
          profile_id: Profiles.PERSONAL,
          elements: { 
            cantDeposits: true,
            listDepositZoneEmpty: true,
          }
        },
        primaryColor: '',
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */
      this.configWidget.elements.cantDeposits = Helper.hasAccessWidget(this.configWidget, 'cant_deposits')
      this.configWidget.elements.listDepositZoneEmpty = Helper.hasAccessWidget(this.configWidget, 'list_deposit_zone_empty')      
      /* Fin configuracion */
    },
    mounted() {
      this.setup()
    }, 
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
      openDeposits() {
        this.$router.push({ name: 'DepositsStaffCrudDeposits' })
      },
      openDepositsOperations() {
        this.$router.push({ name: 'DepositsStaffCrudDepositsOperations' })
      },
      openDepositsQuery() {
        this.$router.push({ name: 'DepositsStaffCrudDepositsQuery' })
      },      
      openDepositsQueryLocations() {
        this.$router.push({ name: 'DepositsStaffCrudDepositsQueryLocations' })
      }            
    }    
   
  }
</script>

<style>

</style>
