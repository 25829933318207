import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerDepositos() {
    var url = ConfigAPI.baseURL + "deposits" + Session.getToken();
    return instance.get(url);
  },
  mostrarDeposito(id) {
    var url = ConfigAPI.baseURL + "deposits/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarDeposito(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "deposits" + Session.getToken();
    return instance.post(url,params);
  },
  editarDeposito(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "deposits/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarDeposito(id) {
    var url = ConfigAPI.baseURL + "deposits/"+ id + Session.getToken();
    return instance.delete(url);
  }, 

  obtenerZonaByPosition(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "deposits-zone/filter/query-position" + Session.getToken()
    return instance.post(url,params);
  },
  editarZona(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "deposits-zone/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarZona(id) {
    var url = ConfigAPI.baseURL + "deposits-zone/"+ id + Session.getToken();
    return instance.delete(url);
  },
  
  editarUbicacion(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "deposits-locations/"+ data.id + Session.getToken();
    return instance.post(url,params);
  }, 
  obtenerUbicacionByPosition(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "deposits-locations/filter/query-position" + Session.getToken()
    return instance.post(url,params);
  },   
  
  obtenerOperacion() {
    var url = ConfigAPI.baseURL + "deposits-operation" + Session.getToken();
    return instance.get(url);
  },
  mostrarOperacion(id) {
    var url = ConfigAPI.baseURL + "deposits-operation/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarOperacion(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "deposits-operation" + Session.getToken();
    return instance.post(url,params);
  },
  editarOperacion(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "deposits-operation/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarOperacion(id) {
    var url = ConfigAPI.baseURL + "deposits-operation/"+ id + Session.getToken();
    return instance.delete(url);
  }, 
  filtrarOperacion(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "deposits-operation/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },   

  filtrarActivity(data,page) {
    var params = data    
    var url = ConfigAPI.baseURL + "deposits-operation-activity/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);    
  },
  filtrarActivityGroupDeposit(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "deposits-operation-activity/filter-group/query" + Session.getToken();
    return instance.post(url,params);    
  },

  obtenerVoucherByReference(data){
    var params = data
    var url = ConfigAPI.baseURL + "deposits-type-operation/get-voucher/reference" + Session.getToken();
    return instance.post(url,params);    
  }
  
}

export default servicesAPI;